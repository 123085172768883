import React from "react";
import "./Card.css";
import { Link } from "react-router-dom";

const Card = (props) => {
  return (
    <div className="card">
      {props.image && <img src={props.image} alt="card" />}
      {props.heading && <h3>{props.heading}</h3>}
      {props.desc && <h2 className="card__desc">{props.desc}</h2>}
      {props.bio && <h2 className="card__bio">{props.bio}</h2>}
      {props.btnTitle && <Link to={props.btnLink}>{props.btnTitle}</Link>}
    </div>
  );
};

export default Card;

// <Card
// image=""
// heading=""
// bio=""
// desc=""
// btnTitle=""
// btnLink=""
// />
