import React, { useState, useEffect } from "react";
import "./LiveStreaming.css";
import { client } from "../../client";
import { Link } from "react-router-dom";

const LiveStreaming = (props) => {
  const [liveStreamsData, setLiveStreamsData] = useState([]);

  useEffect(() => {
    const query = '*[_type == "livestreaming"]';
    client.fetch(query).then((data) => setLiveStreamsData(data));
  }, []);

  // console.log(liveStreamsData);

  return (
    <section className="live__streaming_section">
      <h2>Live Streaming Videos</h2>
      <div className="live__streaming__container">
        {props.isLanding &&
          liveStreamsData.slice(0, 3).map((el, ind) => {
            return (
              <div key={ind} className="youtube__videos__card">
                <h3>{el.headline}</h3>
                <iframe
                  src={el.embeddedlink}
                  title={el.headline}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
                <p>{el.description}</p>
              </div>
            );
          })}

        {!props.isLanding &&
          liveStreamsData.map((el, ind) => {
            return (
              <div key={ind} className="youtube__videos__card">
                <h3>{el.headline}</h3>
                <iframe
                  src={el.embeddedlink}
                  title={el.headline}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
                <p>{el.description}</p>
              </div>
            );
          })}
      </div>
      <div className="view__more__btn__container">
        {props.isLanding && (
          <Link className="view__more__btn" to="/livestreams">
            View More
          </Link>
        )}
      </div>
    </section>
  );
};

export default LiveStreaming;
