export const ArtEvents = [
  {
    artEventName: "Kashmir Art Camp 2023",
    artEventSubHeadline: "Swarg Again: Residencial art camp in Kashmir",
    artEventDesc:
      "In 19th to 23rd May 2023, the enchanting valleys of Kashmir hosted Wadiyan, a residential art camp. With esteemed guest artists Madhu Kumar, Sikander Singh, Vilas Kulkarni, and Bijay Biswaal, the event was an artistic paradise. Surrounded by nature's beauty, the camp inspired creativity among participants. Plein-air sessions again captured Kashmir's charm on canvas. The camp concluded with a stunning art exhibition. Wadiyan left an indelible mark on the hearts of all, promising to be an unforgettable artistic retreat in paradise.",
    imgUrl_1: "/Icons/events/Kashmir_2023/img1.jpg",
    imgUrl_2: "/Icons/events/Kashmir_2023/img2.jpg",
    mentorImg_1: "/Icons/events/mentor/Achintya.jpg",
    mentorName_1: "Ex - Army Achintya Hazra",
    mentorImg_2: "/Icons/events/mentor/nishikant.jpg",
    mentorName_2: "Artist Nishikant Palande",
    guestImg_1: "/Icons/events/guest/Bijay.jpg",
    guestName_1: "Artist Bijay Biswaal",
    guestImg_2: "/Icons/events/guest/vilas.jpg",
    guestName_2: "Artist Vilas Kulkarni",
    guestImg_3: "/Icons/events/guest/madhu.jpg",
    guestName_3: "Artist Madhu Kumar",
    guestImg_4: "/Icons/events/guest/Sikander.JPEG",
    guestName_4: "Artist Sikander Singh",
  },
  {
    artEventName: "Khajuraho Art Camp 2023",
    artEventSubHeadline: "Kalasutra: Residencial art workshop in Khajuraho",
    artEventDesc:
      "Kalaarambh's Kalasutra, an art residential workshop, took place from 9th  to 12th  March 2023 in the enchanting city of Khajuraho. Renowned artists Bijay Biswal, Vijay Achrekar, Manoj Sakle, and Sikander Singh guided participants through an inspiring pleinair sessions. From landscape painting to portrait demonstration, attendees immersed themselves into learning form the masters. It was a transformative experience, leaving participants with unforgettable memories and newfound artistic perspectives.",
    imgUrl_1: "/Icons/events/Khajuraho_2023/img2.jpg",
    imgUrl_2: "/Icons/events/Khajuraho_2023/img1.jpg",
    mentorImg_1: "/Icons/events/mentor/Achintya.jpg",
    mentorName_1: "Ex - Army Achintya Hazra",
    mentorImg_2: "/Icons/events/mentor/nishikant.jpg",
    mentorName_2: "Artist Nishikant Palande",
    guestImg_1: "/Icons/events/guest/Vijay.png",
    guestName_1: "Artist Vijay Achrekar",
    guestImg_2: "/Icons/events/guest/Bijay.jpg",
    guestName_2: "Artist Bijay Biswaal",
    guestImg_3: "/Icons/events/guest/manoj.jpg",
    guestName_3: "Artist Manoj Sakale",
    guestImg_4: "/Icons/events/guest/Sikander.JPEG",
    guestName_4: "Artist Sikander Singh",
  },
  {
    artEventName: "Rajasthan Art Camp 2022 ",
    artEventSubHeadline: "Bundi Again : Residencial Art Workshop in Bundi ",
    artEventDesc:
      "The Rajasthan Art Camp in Bundi took place from 4th to 8th Nov 2022,  featuring renowned artists Kudalaya Hiremath, Amol Pawar, Vikrant Shitole, Krishn Kundra and Sikander Singh. Set against Bundi's picturesque backdrop, the event offered workshops and interactive sessions, fostering creativity and artistic growth. Participants drew inspiration from the town's beautiful architecture. The camp left everyone with cherished memories and renewed artistic passion. The tremendous art and the architecture look so heart-touching that they bring the Kalaarambh back to Bundi.",
    imgUrl_1: "/Icons/events/Rajasthan_2023/img1.jpg",
    imgUrl_2: "/Icons/events/Rajasthan_2023/img2.jpg",
    mentorImg_1: "/Icons/events/mentor/Achintya.jpg",
    mentorName_1: "Ex - Army Achintya Hazra",
    mentorImg_2: "/Icons/events/mentor/nishikant.jpg",
    mentorName_2: "Artist Nishikant Palande",
    guestImg_1: "/Icons/events/guest/kudalayya_hiremath.jpg",
    guestName_1: "Artist Kudalayya Hiremath",
    guestImg_2: "/Icons/events/guest/vikrant.jpg",
    guestName_2: "Artist Vikrant Shitole",
    guestImg_3: "/Icons/events/guest/amol.jpg",
    guestName_3: "Artist Amol Pawar",
    guestImg_4: "/Icons/events/guest/Sikander.JPEG",
    guestName_4: "Artist Sikander Singh",
  },
  {
    artEventName: "Kashmir Art Camp 2022",
    artEventSubHeadline: "Swarg: Residencial art camp in Kashmir",
    artEventDesc:
      "In April 2022, the enchanting valleys of Kashmir hosted Swarg, a residential art camp. With esteemed guest artists Bijay Biswal, Sikander Singh, Amol Pawar, and Vikrant Shitole, the event was an artistic paradise. Surrounded by nature's beauty, the camp inspired creativity among participants. Plein-air sessions captured Kashmir's charm on canvas. The camp was concluded with a stunning art exhibition. Swarg left an indelible mark on the hearts of all, promising to be an unforgettable artistic retreat in paradise.",
    imgUrl_1: "/Icons/events/Kashmir_2022/img1.jpg",
    imgUrl_2: "/Icons/events/Kashmir_2022/img2.jpg",
    mentorImg_1: "/Icons/events/mentor/Achintya.jpg",
    mentorName_1: "Ex - Army Achintya Hazra",
    mentorImg_2: "/Icons/events/mentor/nishikant.jpg",
    mentorName_2: "Artist Nishikant Palande",
    guestImg_1: "/Icons/events/guest/Bijay.jpg",
    guestName_1: "Artist Bijay Biswaal",
    guestImg_2: "/Icons/events/guest/vikrant.jpg",
    guestName_2: "Artist Vikrant Shitole",
    guestImg_3: "/Icons/events/guest/amol.jpg",
    guestName_3: "Artist Amol Pawar",
    guestImg_4: "/Icons/events/guest/Sikander.JPEG",
    guestName_4: "Artist Sikander Singh",
  },
  {
    artEventName: "Rajasthan Art Camp 2022",
    artEventSubHeadline: "Bundi : Residencial Art Workshop in Bundi ",
    artEventDesc:
      "The Rajasthan Art Camp in Bundi took place from 22nd to 26th Jan 2022, featuring renowned artists Ganesh Hire, Vikrant Shitole, and Sikander Singh. Set against Bundi's picturesque backdrop, the event offered workshops and interactive sessions, fostering creativity and artistic growth. Participants drew inspiration from the town's beautiful architecture. The camp left everyone with cherished memories and renewed artistic passion.",
    imgUrl_1: "/Icons/events/Rajasthan_2022/img1.jpg",
    imgUrl_2: "/Icons/events/Rajasthan_2022/img2.jpg",
    mentorImg_1: "/Icons/events/mentor/Achintya.jpg",
    mentorName_1: "Ex - Army Achintya Hazra",
    mentorImg_2: "/Icons/events/mentor/nishikant.jpg",
    mentorName_2: "Artist Nishikant Palande",
    guestImg_1: "/Icons/events/guest/Bijay.jpg",
    guestName_1: "Artist Bijay Biswaal",
    guestImg_2: "/Icons/events/guest/vikrant.jpg",
    guestName_2: "Artist Vikrant Shitole",
    guestImg_3: "/Icons/events/guest/ganesh.jpg",
    guestName_3: "Artist Ganesh Hire",
    guestImg_4: "/Icons/events/guest/Sikander.JPEG",
    guestName_4: "Artist Sikander Singh",
  },
  {
    artEventName: "Kolkata Art Camp 2021",
    artEventSubHeadline: `"Kuch Meethaho Jaye" Art Tour in Kolkata - A Plein Air Painting Delight`,
    artEventDesc: `In October 2021, Kalaarambh, an organization dedicated to art and culture, organized the "Kuch Meethaho Jaye" art tour in Kolkata. Renowned artists from all over India gathered to indulge in the captivating practice of plein air painting. For a week, the vibrant streets of Kolkata turned into open-air studios, witnessing the artists' masterful strokes and creativity as they captured the city's essence. "Kuch Meethaho Jaye" celebrated Kolkata's artistic charm and united artists from different regions, fostering a deep appreciation for the city's rich heritage.`,
    imgUrl_1: "/Icons/events/Kolkata_2021/img1.jpg",
    imgUrl_2: "/Icons/events/Kolkata_2021/img2.jpeg",
    mentorImg_1: "/Icons/events/mentor/Achintya.jpg",
    mentorName_1: "Ex - Army Achintya Hazra",
    mentorImg_2: "/Icons/events/mentor/nishikant.jpg",
    mentorName_2: "Artist Nishikant Palande",
    guestImg_1: "/Icons/events/guest/Bijay.jpg",
    guestName_1: "Artist Bijay Biswaal",
    guestImg_2: "/Icons/events/guest/vikrant.jpg",
    guestName_2: "Artist Vikrant Shitole",
    guestImg_3: "/Icons/events/guest/Kolkata_Invited_artist/Alka_vora.jpg",
    guestName_3: "Artist Alka Vora",
    guestImg_4: "/Icons/events/guest/Sikander.JPEG",
    guestName_4: "Artist Sikander Singh",
    guestImg_5: "/Icons/events/guest/Kolkata_Invited_artist/Arnab_bera.jpg",
    guestName_5: "Artist Arnab Bera",
    guestImg_6: "/Icons/events/guest/Kolkata_Invited_artist/Arti_patel.jpg",
    guestName_6: "Artist Arti Patel",
    guestImg_7: "/Icons/events/guest/Kolkata_Invited_artist/Avi_dey.jpg",
    guestName_7: "Artist Avi Dey",
    guestImg_8: "/Icons/events/guest/Kolkata_Invited_artist/Chinmay_panda.jpg",
    guestName_8: "Artist Chinmay Panda",
    guestImg_9: "/Icons/events/guest/Kolkata_Invited_artist/Madhusudan_das.jpg",
    guestName_9: "Artist Madhusudan Das",
  },
  {
    artEventName: "Varanasi Art Camp 2021",
    artEventSubHeadline: "",
    artEventDesc:
      "Jaltarang, a residential art workshop in Varanasi, took place from 19th to 21st March. Guest artists Sanjeev Joshi, Aniket Mahale, Anirban Jana, and Sikander Singh joined the beautiful plein air painting event organized by Kalaarambh. Varanasi's beauty lies in its timeless charm, where ancient temples, bustling ghats, and the sacred Ganges come together to create a mesmerizing mixture of culture and spirituality.The participants explored various techniques amidst the enchanting landscapes of Varanasi, forging lasting connections and creating unforgettable memories.",
    imgUrl_1: "/Icons/events/Varanasi_2021/img1.jpg",
    imgUrl_2: "/Icons/events/Varanasi_2021/img2.jpg",
    mentorImg_1: "/Icons/events/mentor/Achintya.jpg",
    mentorName_1: "Ex - Army Achintya Hazra",
    mentorImg_2: "/Icons/events/mentor/nishikant.jpg",
    mentorName_2: "Artist Nishikant Palande",
    guestImg_1: "/Icons/events/guest/Sikander.JPEG",
    guestName_1: "Artist Sikander Singh",
    guestImg_2: "/Icons/events/guest/Sanjeev.jpg",
    guestName_2: "Artist Sanjeev Joshi",
    guestImg_3: "/Icons/events/guest/Aniket.jpg",
    guestName_3: "Artist Aniket Mahale",
    guestImg_4: "/Icons/events/guest/Anirbhan.jpg",
    guestName_4: "Artist Anirbhan Jana",
  },
  {
    artEventName: "Pune Art Camp 2020",
    artEventSubHeadline: "",
    artEventDesc:
      "Green Wash was a residential art workshop held in Pune from January 23rd to January 25th, 2020. The event featured internationally renowned guest artists Milind Mullick, Sanjay Desai, Aniket Mahale, Gulshan Achari, and Amit Dhane. Organized by Kalaarambh, it offered a beautiful plein air painting experience, surrounded by Pune's picturesque landscapes. Participants improved their skills under the guidance of the talented artists, creating lasting memories and inspiring their artistic journeys.",
    imgUrl_1: "/Icons/events/Pune_2020/img1.jpg",
    imgUrl_2: "/Icons/events/Pune_2020/img2.jpg",
    mentorImg_1: "/Icons/events/mentor/Achintya.jpg",
    mentorName_1: "Ex - Army Achintya Hazra",
    mentorImg_2: "/Icons/events/mentor/nishikant.jpg",
    mentorName_2: "Artist Nishikant Palande",
    guestImg_1: "/Icons/events/guest/Sanjay.jpg",
    guestName_1: "Artist Sanjay Desai",
    guestImg_2: "/Icons/events/guest/milind.jpg",
    guestName_2: "Artist milind Mulick",
    guestImg_3: "/Icons/events/guest/Aniket.jpg",
    guestName_3: "Artist Aniket Mahale",
    guestImg_4: "/Icons/events/guest/Amit.jpg",
    guestName_4: "Artist Amit Dhane",
  },
];
