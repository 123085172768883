import React from "react";
import "./EventCard.css";
import Card from "../Card/Card";

const EventCard = (props) => {
  return (
    <div className="event__card">
      <h2 className="event__headline">{props.data.artEventName}</h2>
      <p className="event__subheading">{props.data.artEventSubHeadline}</p>

      <div className="event__images">
        <Card image={props.data.imgUrl_1} />
        <Card image={props.data.imgUrl_2} />
      </div>

      <p className="event__desc">{props.data.artEventDesc}</p>

      <div className="event__artists__images">
        <h3 className="event__card___title">Mentor Artists</h3>
        <div className="event__mentors">
          <div className="guest_artist_card">
            <Card
              image={props.data.mentorImg_1}
              desc={props.data.mentorName_1}
            />
          </div>

          <div className="guest_artist_card">
            <Card
              image={props.data.mentorImg_2}
              desc={props.data.mentorName_2}
            />
          </div>
        </div>
        <h3 className="event__card___title">Guest Artists</h3>
        <div className="guest__artists">
          {props.data.guestImg_1 && (
            <div className="guest_artist_card">
              <Card
                image={props.data.guestImg_1}
                desc={props.data.guestName_1}
              />
            </div>
          )}

          {props.data.guestImg_2 && (
            <div className="guest_artist_card">
              <Card
                image={props.data.guestImg_2}
                desc={props.data.guestName_2}
              />
            </div>
          )}

          {props.data.guestImg_3 && (
            <div className="guest_artist_card">
              <Card
                image={props.data.guestImg_3}
                desc={props.data.guestName_3}
              />
            </div>
          )}

          {props.data.guestImg_4 && (
            <div className="guest_artist_card">
              <Card
                image={props.data.guestImg_4}
                desc={props.data.guestName_4}
              />
            </div>
          )}

          {props.data.guestImg_5 && (
            <div className="guest_artist_card">
              <Card
                image={props.data.guestImg_5}
                desc={props.data.guestName_5}
              />
            </div>
          )}

          {props.data.guestImg_6 && (
            <div className="guest_artist_card">
              <Card
                image={props.data.guestImg_6}
                desc={props.data.guestName_6}
              />
            </div>
          )}

          {props.data.guestImg_7 && (
            <div className="guest_artist_card">
              <Card
                image={props.data.guestImg_7}
                desc={props.data.guestName_7}
              />
            </div>
          )}

          {props.data.guestImg_8 && (
            <div className="guest_artist_card">
              <Card
                image={props.data.guestImg_8}
                desc={props.data.guestName_8}
              />
            </div>
          )}

          {props.data.guestImg_9 && (
            <div className="guest_artist_card">
              <Card
                image={props.data.guestImg_9}
                desc={props.data.guestName_9}
              />
            </div>
          )}

          {props.data.guestImg_10 && (
            <div className="guest_artist_card">
              <Card
                image={props.data.guestImg_10}
                desc={props.data.guestName_10}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventCard;
