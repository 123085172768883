import React from "react";
import "./Footer.css";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__links__container">
        <div className="footer__cols">
          <div className="footer__logo_container">
            <img src="/Icons/logo.png" alt="footer_logo" />
          </div>
          <div className="footer__details">
            <CallIcon />
            <a href="tel:+91 7009162970">+91 7009162970</a>
          </div>
          <div className="footer__details">
            <EmailIcon />
            <a href="mailto:kalaarambhprivate@gmail.com?&amp;subject=Hey There, I am a user from your website.">
              kalaarambhprivate@gmail.com
            </a>
          </div>
          <div className="footer__details">
            <LocationOnIcon />
            <a href="https://www.google.com/maps/place/22%C2%B034'28.9%22N+87%C2%B059'28.9%22E/@22.5746799,87.9913674,17z/data=!4m4!3m3!8m2!3d22.5746799!4d87.9913674?hl=en">
              Vill+P.O-Betai, P.S-Amta, Teh- Uluberia, Dist- Howrah, Pin-711401,
              State-West Bengal, India
            </a>
          </div>
        </div>

        <div className="footer__cols quick__links__col">
          <h4>Quick Links</h4>
          <div className="footer__details">
            <ArrowRightIcon />
            <Link to="/upcoming-events">Upcoming Events</Link>
          </div>
          <div className="footer__details">
            <ArrowRightIcon />
            <Link to="/victorious-events">Victorious Events</Link>
          </div>
          <div className="footer__details">
            <ArrowRightIcon />
            <Link to="/livestreams">Live Streams</Link>
          </div>
          <div className="footer__details">
            <ArrowRightIcon />
            <Link to="/news">News & Blogs</Link>
          </div>
          <div className="footer__details">
            <ArrowRightIcon />
            <Link to="/donate">Donate</Link>
          </div>
          <div className="footer__details">
            <ArrowRightIcon />
            <Link to="/contact">Contact</Link>
          </div>
        </div>

        <div className="social_media_container">
          <a href="https://www.youtube.com/@KALAARAMBH">
            <YouTubeIcon />
          </a>

          <a href="https://wa.me/+917888716284">
            <WhatsAppIcon />
          </a>

          <a href="https://www.instagram.com/kala_arambh/?igshid=MzRlODBiNWFlZA%3D%3D">
            <InstagramIcon />
          </a>

          <a href="https://www.facebook.com/kala.arambh?mibextid=ZbWKwL">
            <FacebookIcon />
          </a>

          <a href="https://www.threads.net/@kala_arambh">
            <TwitterIcon />
          </a>
        </div>
      </div>

      <div className="footer__copyright">
        Copyright © 2023 Kalaarambh | All Rights Reserved by Kalaarambh!
      </div>
    </footer>
  );
};

export default Footer;
