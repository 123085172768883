import React, { useRef } from "react";
import "./Contact.css";

const Contact = () => {
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const messageRef = useRef(null);

  const handleSubmit = () => {
    setTimeout(() => {
      firstNameRef.current.value = "";
      lastNameRef.current.value = "";
      emailRef.current.value = "";
      messageRef.current.value = "";
    }, 5000);
  };

  return ( 
    
    <div>
      <div className="contact__heading">
        <h2>Contact Us</h2>
      </div>
      <form
        action="https://formspree.io/f/xpzgqedl"
        method="POST"
        name="contact_page"
        className="contact__form"
        onSubmit={() => handleSubmit()}
      >
        <div className="form__container">
          <h4>SEND US A MESSAGE</h4>
          <div className="box">
            <label htmlFor="firstname">First name</label> <br />
            <input
              ref={firstNameRef}
              id="firstname"
              name="firstname"
              type="text"
              placeholder="First name"
              required
            />
          </div>
          <div className="box">
            <label htmlFor="lastname">Last name</label> <br />
            <input
              ref={lastNameRef}
              id="lastname"
              name="lastname"
              type="text"
              placeholder="Last name"
              required
            />
          </div>

          <div className="email">
            <label htmlFor="emailid">Email</label> <br />
            <input
              ref={emailRef}
              id="emailid"
              type="email"
              name="email"
              placeholder="Enter your mail"
              required
            />
          </div>
          <div className="message">
            <label htmlFor="query">Message</label> <br />
            <textarea name="message" id="query" ref={messageRef}></textarea>
          </div>

          <input type="submit" value="Submit" className="submit--here" />
        </div>
        <div className="image">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3684.118259127388!2d87.98917871495962!3d22.574679885181723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjLCsDM0JzI4LjkiTiA4N8KwNTknMjguOSJF!5e0!3m2!1sen!2sin!4v1689238970325!5m2!1sen!2sin"
            width="600"
            height="450"
            style={{ border: "0" }}
            title="Office Address"
            // allowfullscreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </form>

      <div className="icon">
        <div className="location">
          <a
            style={{ marginTop: "25px" }}
            href="https://www.google.com/maps/place/22%C2%B034'28.9%22N+87%C2%B059'28.9%22E/@22.5746799,87.9913674,17z/data=!4m4!3m3!8m2!3d22.5746799!4d87.9913674?hl=en"
          >
            <div className="box">
              <i className="fa-solid fa-location-dot"></i>
            </div>
          </a>

          <span className="contact--info">Our Location</span>
          <span className="details">
            Vill+P.O-Betai, P.S-Amta, Teh- Uluberia, Dist- Howrah, Pin-711401,
            State-West Bengal, India
          </span>
        </div>

        <div className="phone">
          <a href="tel:+91 7009162970">
            <div className="box">
              <i className="fa-solid fa-phone"></i>
            </div>
          </a>

          <span className="contact--info">Our Phone</span>
          <span className="details">+91 7009162970 </span>
        </div>

        <div className="emailicon">
          <a href="mailto:kalaarambhprivate@gmail.com?&amp;subject=Hey There, I am a user from your website.">
            <div className="box">
              <i className="fa-solid fa-envelope"></i>
            </div>
          </a>
          <span className="contact--info">Our Email id</span>
          <span className="details">kalaarambhprivate@gmail.com</span>
        </div>
      </div>
    </div>
  );
};

export default Contact;
