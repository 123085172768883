export const Donors = [
  "Mrs. Rita Purohit",
  "Mrs. Chetna Vora",
  "Mrs. Priya Bajaj",
  "Mrs. Gitika Adhikari",
  "Mr. Kanaka Shivkumar ",
  "Mrs. Hina Dakheja",
  "Mrs. Madhavika  Kabare ",
  "Mrs. Anjali Agarwal ",
  "Mr. Bijay Biswaal",
  "Mrs. Lalita Gupta",
  "Mrs. Kishwar Nensey ",
  "Mr. Ketan Rane ",
  "Mr. Vilas Bendre",
  "Mrs. Alka Vora ",
  "Mr. Sourav Sagar ",
  "Mr. Uma Maheshwari",
  "Mrs. Prerna Kumar",
];
