import React from "react";
import "./HeroSection.css";

const HeroSection = () => {
  return (
    <section className="hero__section">
      <img src="/Icons/Hero_image.jpg" alt="Kashmir Art Camp" />
      <div className="aim__section">
        <h2>Kalaarambh Aim</h2>
        <div className="aim__desc">
          <span>
            Kalaarambh is an organization founded by two acclaimed artists,
            Nishikant Palande and Achintya Hazra. Their aim is to connect
            artists across India, offering workshops, exhibitions, and
            interactive sessions. They have the support of eminent artists and
            renowned sponsors, working together to strengthen the Indian Art
            Communities.
          </span>
          <span className="hide_aim__desc_1">
            This organization aspires to reach out to amateur artists as well as
            professionals and lead them towards the path of personal success.
            Most importantly, we aim to eliminate the distance between the
            masters and the students so that they can get proper guidance
            without fear and gain unadulterated inspirations for their future.
          </span>
          <span className="hide_aim__desc_2">
            Kalaarambh is associated with many eminent artists who are
            continuously aiding us with their presence for making each event a
            successful endeavour
          </span>
          {/* <span>
            Kalaraambh have plans to host plethora of extremely exciting events,
            art tours, residential workshop, interactive/ discussion session,
            free live demonstration, painting exhibition, art material giveaways
            and much more back-to-back in 2022.
          </span>
          <span>
            Our organization is also supported and sponsored by many renowned
            sponsors so that our participants can have an elevated experience
            which is unmatched.
          </span>
          <span>
            Lastly, our aim is to synergize Indian Art Communities so that we
            can build a stronger relationship with each other. Small steps
            Towards Art
          </span> */}
        </div>
        <p></p>
      </div>
    </section>
  );
};

export default HeroSection;
