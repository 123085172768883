export const MembersData = [
  {
    name: "Mrs. Alka Vora",
    img: "/Icons/kalaarambh/Members/Alka.jpg",
    role: "Chief coordinator",
    location: "Mumbai ",
    bio: "Freelance artist work in different mediums, also treasure of very old The Art Society of India",
  },
  {
    name: "Mr. Kulwinder Singh",
    img: "/Icons/kalaarambh/Members/kulwinder.jpg",
    role: "State head of Himachal Pradesh",
    location: "Himachal Pradesh",
    bio: "Freelance artist from Himachal Pradesh, working in oil, Acrylic and watercolour medium",
  },
  {
    name: "Mrs. Jyoti Verma",
    img: "/Icons/kalaarambh/Members/Jyoti.jpg",
    role: "Social Media Coordinator ",
    location: "Bangalore",
    bio: "Freelance artist and working in different mediums",
  },
  {
    name: "Miss Vanshika ",
    img: "/Icons/kalaarambh/Members/Vanshika.jpg",
    role: "Social Media Coordinator ",
    location: "Chandigarh",
    bio: "Miss Vanshika is a vibrant and enthusiastic individual working as a freelancing artist.",
  },
  {
    name: "Miss Kanishtha Bhatia",
    img: "/Icons/kalaarambh/Members/kanishtha.jpg",
    role: "Social Media Coordinator",
    location: "Delhi NCR (Faridabad)",
    bio: "Freelance artist and a teacher working in different mediums and Owning a studio - KaladiariesbyKanishtha",
  },
  {
    name: "Mr. Siddharth Jena ",
    img: "/Icons/kalaarambh/Members/Siddharth.jpg",
    role: "Social Media Coordinator ",
    location: "Odisha ",
    bio: "Recognized for hyper-realistic portraits and figure studies. Currently a social media coordinator at KALAARAMBH",
  },
  {
    name: "Mr. Priyansh Soni",
    img: "/Icons/kalaarambh/Members/Priyansh.jpg",
    role: "Social Media Coordinator ",
    location: "Bundi, Rajasthan ",
    bio: "Mr. Priyansh is a Freelance artist who start his art journey when he was 6 years old. Currently a social media coordinator",
  },
];
