import React from "react";
import "./Founder.css";

const Founder = () => {
  return (
    <section className="founders__section">
      <h2>Founders</h2>

      <div className="founder__container">
        <div className="founders__row">
          <div className="founders__img">
            <img src="/Icons/events/mentor/Achintya.jpg" alt="Achintya" />
            <span>Mr. Achintya Hazra</span>
            <span>Location : Kolkata</span>
          </div>
          <p className="founders__desc">
            Renowned artist known for vibrant classical artworks in Oil, Acrylic
            & Watercolour. His international exhibitions and numerous awards
            solidify his rising star status in the art community. His journey
            from the Army to becoming an artist showcases the power of
            creativity to overcome challenges. He is the founder of Kalaarambh
            and the brand ambassador of Artikate, inspiring and nurturing
            artistic talent.
          </p>
        </div>

        <div className="founders__row">
          <div className="founders__img">
            <img src="/Icons/events/mentor/nishikant.jpg" alt="nishikant" />
            <span>Mr. Nishikant Palande</span>
            <span>Location : Navi Mumbai</span>
          </div>
          <p className="founders__desc">
            Mumbai artist & owner of Thought-line Design Studio. Collaborated
            with Tanishq & Absolut India. Founder of Kalaarambh, non-profit for
            artists. Known for realistic portraits, awards, workshops, mentor to
            youth. Associated with The Art Society India & Urban Sketchers Navi
            Mumbai.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Founder;
