import React from "react";
import "./Events.css";
import EventCard from "../../Components/EventCard/EventCard";
import { ArtEvents } from "../../Assets/Art_Events_Data";

const Events = () => {
  return (
    <div className="event__container">
      <h2 className="headline">Our Art Events</h2>
      {ArtEvents.map((el, ind) => (
        <EventCard data={el} key={ind} />
      ))}
    </div>
  );
};

export default Events;
