import React from "react";
import Card from "../Card/Card";
import "./Members.css";
import { MembersData } from "../../Assets/Members_Data";

const Members = () => {
  return (
    <section className="members__section">
      <h2>Members</h2>
      <div className="members__container">
        {MembersData.map((el, ind) => {
          return (
            <Card
              key={ind}
              image={el.img}
              heading={el.name}
              bio={el.bio}
              desc={el.role}
            />
          );
        })}
      </div>
    </section>
  );
};

export default Members;
