import React from "react";
import "./News.css";
import Card from "../../Components/Card/Card";

const News = () => {
  return (
    <>
      <h2 className="headline">News and Blogs</h2>
      <div className="news__container">
        <Card image="/Icons/News/News1.jpeg" />
        <Card image="/Icons/News/News2.jpeg" />
        <Card image="/Icons/News/News3.jpeg" />
        <Card image="/Icons/News/News4.jpeg" />
        <Card image="/Icons/News/News5.jpeg" />
        <Card image="/Icons/News/News6.jpeg" />
        <Card image="/Icons/News/News7.jpeg" />
        <Card image="/Icons/News/News9.jpeg" />
        <Card
          image="/Icons/News/News10.jpg"
          btnLink="https://www.thekashmirmonitor.net/indian-artists-create-artwork-stay-for-kalaarambh-in-kashmir-in-srinagar/amp/"
          btnTitle="Read More"
        />
        <Card
          image="/Icons/News/News11.png"
          btnLink="https://www.pixstory.com/story/kalaarambh-in-kashmir/93707"
          btnTitle="Read More"
        />
      </div>
    </>
  );
};

export default News;
