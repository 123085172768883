import React from "react";
import "./Landing.css";
import HeroSection from "../../Components/HeroSection/HeroSection";
import Founder from "../../Components/FounderSection/Founder";
import Members from "../../Components/MemberSection/Members";
import LiveStreaming from "../../Components/LiveStreaming/LiveStreaming";

const Landing = () => {
  return (
    <div className="main__container">
      <HeroSection />
      <Founder />
      <Members />
      <LiveStreaming isLanding={true} />
    </div>
  );
};

export default Landing;
