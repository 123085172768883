import React from "react";
import "./Donate.css";
import Card from "../../Components/Card/Card";
import { Donors } from "../../Assets/Donors_Data";

const Donate = () => {
  return (
    <div className="donate_container">
      <h2>Donate Us</h2>
      <div className="donate__details_container">
        <div className="donate__bank_details_container">
          <h4>Bank Details-</h4>
          <span>Name- Kalaarambh</span>
          <span>State Bank of India</span>
          <span>Account no-41519526257</span>
          <span>IFSC CODE- SBIN0004787</span>
          <span>Branch-Amta.</span>
          <span>UPI- 41519526257@SBI</span>
        </div>
        <div className="donate__Qr_image_container">
          <img src="/Icons/Kalaarambh_Qr.jpeg" alt="Qr" />
        </div>
      </div>

      <h2 className="ourDonors__heading">Our Donors</h2>
      <div className="our__donors">
        {Donors.map((el, ind) => (
          <Card key={ind} heading={el} />
        ))}
      </div>
    </div>
  );
};

export default Donate;
