import React, { useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [activateNav, setActivateNav] = useState(false);
  const burgerClickedHandler = () => setActivateNav((prevData) => !prevData);
  const disableNavHandler = () => setActivateNav((prevData) => !prevData);

  return (
    <header className="header">
      <div className="logo_container">
        <img src="/Icons/logo.png" alt="logo" />
      </div>

      <nav className="nav">
        <ul className={`nav__links  ${activateNav && "navBarActive"}`}>
          <li>
            <Link to="/" onClick={disableNavHandler}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/upcoming-events" onClick={disableNavHandler}>
              Upcoming Events
            </Link>
          </li>
          <li>
            <Link to="/victorious-events" onClick={disableNavHandler}>
              Victorious Events
            </Link>
          </li>

          <li>
            <Link to="/livestreams" onClick={disableNavHandler}>
              Live Streams
            </Link>
          </li>
          <li>
            <Link to="/news" onClick={disableNavHandler}>
              News & Blogs
            </Link>
          </li>
          <li>
            <Link to="/donate" onClick={disableNavHandler}>
              Donate
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={disableNavHandler}>
              Contact Us
            </Link>
          </li>
        </ul>
      </nav>
      {/*  */}
      <div
        className={`burger ${activateNav && "toggle"}`}
        onClick={burgerClickedHandler}
      >
        <div className="line line__1"></div>
        <div className="line line__2"></div>
        <div className="line line__3"></div>
      </div>
    </header>
  );
};

export default Navbar;
