import React, { useState, useEffect } from "react";
import "./EventsSection.css";
import { urlFor, client } from "../../client";

const EventsSection = () => {
  const [sanityEventsData, setSanityEventsData] = useState([]);

  useEffect(() => {
    const query = '*[_type == "events"]';
    client.fetch(query).then((data) => setSanityEventsData(data));
  }, []);

  console.log(sanityEventsData);

  return (
    <section className="events__section">
      <h2>Upcoming Events</h2>
      <div className="events__container">
        {sanityEventsData &&
          sanityEventsData.map((el, ind) => {
            return (
              <div className="upcoming_events__card" key={ind}>
                <img src={urlFor(el.banner)} alt={el.headline} />
                <div className="event__card___content">
                  <h2 className="event_headline">{el.headline}</h2>
                  <p className="event__desc">{el.description}</p>
                  <div className="zoom__details">
                    <a href={el.link} className="zoomLink__btn">
                      {el.buttonName}
                    </a>
                    {el.meetingid && (
                      <span className="zoom__details">
                        Meeting Id : <br /> {el.meetingid}
                      </span>
                    )}
                    {el.mettingpassword && (
                      <span className="zoom__details">
                        Meeting Password : <br /> {el.mettingpassword}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </section>
  );
};

export default EventsSection;
