import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./Containers/Landing/Landing";
import Contact from "./Containers/Contact/Contact";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import Dashboard from "./Containers/Dashboard/Dashboard";
import News from "./Containers/News&Blogs/News";
import Donate from "./Containers/Donate/Donate";
import Events from "./Containers/VictoriousEvents/Events";
import UpcomingEvents from "./Containers/UpcomingEvents/UpcomingEvents";
import LiveStreaming from "./Components/LiveStreaming/LiveStreaming";

const App = () => {
  return (
    <main>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Landing />}></Route>
          <Route path="/donate" element={<Donate />}></Route>
          <Route path="/event-dashboard" element={<Dashboard />}></Route>
          <Route path="/news" element={<News />}></Route>
          <Route path="/victorious-events" element={<Events />}></Route>
          <Route path="/upcoming-events" element={<UpcomingEvents />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/livestreams" element={<LiveStreaming />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </main>
  );
};

export default App;
